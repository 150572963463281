import React from "react"
import { graphql, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PartnersSlider from "../components/PartnersSlider";

import { lSVG } from "../components/ArrowLink";

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  const localeID = 'sk_SK';

  return (
    <Layout location={location} title={siteTitle}>
        <Seo title="404" />
        <div className="hPadSec notFound">
            <div className="content center">
                <div>
                    <h1>404</h1>
                    <p>Stránku, ktorú hľadáte sme nenašli 😕</p>
                    <StaticImage src='../images/yummy.png' alt="" placeholder="blurred" height={100}/>
                    <a onClick={()=>navigate(-1)} className="aArow left" style={{color:"#ED6E47"}}>{lSVG("#ED6E47")} Späť do bezpečia</a>
                </div>
            </div>
        </div>
        <PartnersSlider className="gray" localeID={localeID}/>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
